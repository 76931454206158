<template>
  <div>
    <Container class="mt-12 mb-4 lg:mb-24 lg:mt-16">
      <h2 v-if="title && type !== 'filter'"
          class="max-w-3xl text-3xl lg:text-5xl leading-[1.2] text-white tracking-[-1px] mb-6"
      >
        {{ title }}
      </h2>

      <SectionJobListFilter ref="filter"
                            v-if="type === 'filter'"
                            :title="title"
                            :type="selectedType"
                            :location="selectedLocation"
                            :field="selectedField"
      />

      <div v-if="type === 'filter'"
           class="mt-6 lg:mt-14"
      >
        <SectionJobListTable v-for="(category, i) in sortedByCategory"
                             :key="i"
                             :jobs="category.jobs"
                             :title="category.title"
        />
      </div>

      <div v-else-if="type === 'location' || type === 'early'">
        <SectionJobListTable v-if="jobs"
                             :jobs="(jobs.slice(0, 4) as unknown as JobType[])"
                             class="pt-0 pb-0 lg:pb-0"
        />
      </div>

      <div v-if="filteredJobs.length === 0 && type === 'filter'"
           class="max-w-md px-6 mx-auto mt-12 mb-24 text-center text-white md:mt-20 md:mb-40"
      >
        <h3 class="mt-8 text-3xl text-center">
          {{ noResultContent.title }}
        </h3>

        <p class="mt-4 text-lg font-normal text-center text-gray-100">
          {{ noResultContent.copy }}
        </p>

        <button class="relative block mx-auto mt-8 pb-0.5 bottom-line-inverted"
                @click="filter.clearFilter()"
        >
          Clear filters
        </button>
      </div>

      <div v-if="filteredJobs.length === 0 && type !== 'filter'"
           class="text-gray-100"
      >
        <p class="mb-16 -mt-4 text-lg md:mt-8">
          At the moment there are no open positions for the given selection.
          <NuxtLink href="/careers/jobs"
                    class="underline"
          >
            Explore all open positions
          </NuxtLink>
        </p>
      </div>
    </Container>

    <SectionHeading v-if="type === 'location' && filteredJobs.length > 4"
                    class="!-mt-4 lg:!-mt-24"
                    :title="`There are ${filteredJobs.length - 4} additional positions in ${locationLabel}`"
                    type="secondary"
                    :cta="{ title: `Explore positions`, href: `/careers/jobs?location=${location}`, type: 'filled' }"
    />
  </div>
</template>
<script setup lang="ts">
import { useAsyncData } from '#imports';
import { useRoute } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import type { JobType } from '~~/types/jobs';
import useJobFilter from '../../composables/job-filters';
import { SPONTANEOUS_APPLICATION_ID } from '~/data/constants.mjs';

interface JobListingProps {
  title?: string;
  type: 'filter' | 'location' | 'early';
  location?: string;
}

const props = defineProps<JobListingProps>();

const noResultContent = {
  title: 'No results to show.',
  copy: 'No results match the filter criteria. Remove filter or clear all filters to show results.'
};

const filter = ref();
const filteredJobs = ref([]);
const route = useRoute();
const jobFields = useJobFilter('field');
const locations = useJobFilter('location');
const jobTypes = useJobFilter('type');
const selectedType = ref(jobTypes[0]);
const selectedField = ref(jobFields[0]);
const selectedLocation = ref(locations[0]);

onMounted(() => {
  selectedType.value = jobTypes.find(item => item.key === (route.query.type ? route.query.type : 'all'));
  selectedField.value = jobFields.find(item => item.key === (route.query.field ? route.query.field : 'all'));
  selectedLocation.value = locations.find(item => item.key === (route.query.location ? route.query.location : 'all'));

  filterResults();
});

watch(
  () => route.query.type,
  type => {
    selectedType.value = jobTypes.find(item => item.key === (type ? (type as string) : 'all'));
    filterResults();
  }
);

watch(
  () => route.query.field,
  type => {
    selectedField.value = jobFields.find(item => item.key === (type ? (type as string) : 'all'));
    filterResults();
  }
);

watch(
  () => route.query.location,
  type => {
    selectedLocation.value = locations.find(item => item.key === (type ? (type as string) : 'all'));
    filterResults();
  }
);

const filterResults = () => {
  let newJobs = jobs.value;

  if (!newJobs) return;

  if (selectedField.value.key !== 'all') {
    newJobs = newJobs.filter(job => job.category === selectedField.value.label);
  }

  if (selectedLocation.value.key !== 'all') {
    newJobs = newJobs.filter(job => job.locations.some((item: { key: string }) => item.key === selectedLocation.value.key));
  }

  if (selectedType.value.key !== 'all') {
    newJobs = newJobs.filter(job => job.type === selectedType.value.label);
  }

  filteredJobs.value = newJobs;
};

const sortedByCategory = computed(() => {
  let categories = filteredJobs.value.map(job => job.category);
  categories = [...new Set(categories)];
  return categories.map((category: string) => ({ title: category, jobs: filteredJobs.value.filter(job => job.category === category) }));
});

// eslint-disable-next-line no-undef
const { data } = await useAsyncData('jobs', () => queryContent('jobs').find());

// computed content
const jobs = computed(() => {
  if (data && data.value) {
    let newJobs = data.value;

    // Filter the spontaneous job application, since it is not shown here
    newJobs = newJobs.filter(item => item.id !== SPONTANEOUS_APPLICATION_ID);

    if (props.type === 'location') {
      return newJobs.filter(item => item.locations.some((location: { key: string }) => location.key === props.location.toLowerCase()));
    } else if (props.type === 'early') {
      return newJobs.filter(item => item.type === 'Internship' || item.type === 'Student' || item.title.toLowerCase().includes('junior'));
    } else return newJobs;
  }

  return false;
});

// location name with first letter uppercase
const locationLabel = computed(() => {
  if (props.location) {
    return props.location.charAt(0).toUpperCase() + props.location.slice(1);
  }

  return '';
});
</script>
